import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import featureImage from "../../static/images/hero.png";
import thumbnailWatchlist from "../../static/images/feature-watchlist.png"
import thumbnailSearch from "../../static/images/feature-search.png"
import thumbnailEdit from "../../static/images/feature-edit.png"
import thumbnailNotif from "../../static/images/feature-notification.png"

const IndexPage = () => (
    <Layout>
        <SEO title="Custom notifications for reddit"/>

        <div className={"page-header home"}>
            <h1>Custom Notifications for Reddit</h1>
            <p>Keep track of the conversation on your favorite subreddits<br/>by monitoring <span>posts and comments</span></p>
            <img alt={"Dashboard"} src={featureImage}/>
        </div>

        <div className={"container"}>
            <div className={"features"}>
                <div className={"feature__item"}>
                    <div className={"row"}>
                        <div className={"col-6 first"}>
                            <div className={"thumbnail"}>
                                <img alt={"Event"} src={thumbnailWatchlist}/>
                            </div>
                        </div>

                        <div className={"col-6"}>
                            <div className={"feature__content"}>
                                <h2>Beat the trend</h2>
                                <p>Get notified when posts start trending on your favorite subreddits</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"feature__item"}>
                    <div className={"row"}>
                        <div className={"col-6"}>
                            <div className={"feature__content"}>
                                <h2>Dig deep</h2>
                                <p>Create search queries to get notified when a keyword is mentioned in a post or comment</p>
                            </div>
                        </div>

                        <div className={"col-6 first"}>
                            <div className={"thumbnail"}>
                                <img alt={"Board"} src={thumbnailSearch}/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"feature__item"}>
                    <div className={"row"}>
                        <div className={"col-6 first"}>
                            <div className={"thumbnail"}>
                                <img alt={"News"} src={thumbnailEdit}/>
                            </div>
                        </div>

                        <div className={"col-6"}>
                            <div className={"feature__content"}>
                                <h2>Modify your query anytime</h2>
                                <p>Build custom queries that respond to your needs</p>
                            </div>
                        </div>
                    </div>
                </div>


                <div className={"feature__item"}>
                    <div className={"row"}>
                        <div className={"col-6 first"}>
                            <div className={"thumbnail"}>
                                <img alt={"Users"} src={thumbnailNotif}/>
                            </div>
                        </div>

                        <div className={"col-6"}>
                            <div className={"feature__content"}>
                                <h2>Notification center</h2>
                                <p>Keep track of your results and easily navigate to reddit with the tap of a finger</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className={"call-to-action"}>
            <div className={"container"}>
                <div className={"call-to-action__content"}>
                    <h2>Available on iOS</h2>
                    <p>Download the app and get started today!</p>
                </div>

                <div className={"button"}>
                    <a href="https://apps.apple.com/lb/app/karma-hunter/id1515189681" target={"_blank"}>Download</a>
                </div>
            </div>
        </div>
    </Layout>
)

export default IndexPage
